<template>
	<form
		class="main-wrapper mt-4 mb-3"
		@submit.prevent="handleSubmit"
	>
		<CRow>
			<!-- Page level -->
			<CCol lg="12">
				<h4 class="mb-4">
					Static page level
				</h4>
				<p class="label level-label">
					Creating static page
				</p>
				<BaseButtonGroup
					v-model="level"
					:list="PAGE_LEVEL_OPTIONS"
					:disabled="isEditMode"
					@input="handleSelectedLevel"
				/>

				<CRow
					v-if="isParentSelectable"
					class="row-parent-page"
				>
					<CCol md="6" class="mb-3">
						<BaseDropDown
							v-model="$v.pageParentLevelOne.$model"
							:options="pageParentLevelOneOptions"
							:searchable="false"
							:is-valid="!$v.pageParentLevelOne.$error"
							:invalid-feedback="$t('global.error.required')"
							label-drop-down="Under parent page (level 1)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="page-level-one-dropdown"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Page level -->
			<!-- General info -->
			<CCol lg="12">
				<h4 class="mb-4">
					General info
				</h4>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="$v.name.$model"
							:is-valid="!$v.name.$error && null"
							:invalid-feedback="$t('global.error.required')"
							label="Static page name (EN)*"
							@blur="handleAutoFillMetaTitleAndSlug"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.nameTH.$model"
							:is-valid="!$v.nameTH.$error && null"
							:invalid-feedback="$t('global.error.required')"
							label="Static page name (TH)*"
							@blur="handleAutoFillMetaTitleTH"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.customUrl.$model"
							:is-valid="!$v.customUrl.$error && null"
							:invalid-feedback="$t('global.error.url')"
							:description="'Allow only url. example: https://www.studio7thailand.com'"
							label="Custom URL"
						/>
					</CCol>
					<CCol md="12" class="mb-2">
						<CInput
							v-model.trim="$v.slug.$model"
							:is-valid="isValidSlug"
							:invalid-feedback="validateSlugMessage"
							:description="slugHelperText"
							label="Slug"
							@input="handleClearErrorSlug"
						/>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center toggle-group">
						<label class="toggle-label" for="page-status">Status</label>
						<CSwitch
							id="page-status"
							:checked="status"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateStatus"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center toggle-group">
						<label class="toggle-label" for="page-visibility">Store-front visibility</label>
						<CSwitch
							id="page-visibility"
							:checked="visibility"
							:disabled="!status"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateVisibility"
						/>
						<span>{{ getVisibilityText(visibility) }}</span>
					</CCol>
					<template>
						<CCol xl="12" class="d-flex align-items-center toggle-group">
							<label class="toggle-label" for="header-visibility">Header visibility</label>
							<CSwitch
								id="header-visibility"
								:checked="headerVisibility"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
								@update:checked="handleUpdateHeaderVisibility"
							/>
							<span>{{ getVisibilityText(headerVisibility) }}</span>
						</CCol>
						<CCol xl="12" class="d-flex align-items-center toggle-group">
							<label class="toggle-label" for="sidebar-visibility">Sidebar visibility</label>
							<CSwitch
								id="sidebar-visibility"
								:checked="sidebarVisibility"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
								@update:checked="handleUpdateSidebarVisibility"
							/>
							<span>{{ getVisibilityText(sidebarVisibility) }}</span>
						</CCol>
					</template>
				</CRow>
				<hr>
			</CCol>
			<!-- end General info -->
			<!-- Content -->
			<CCol lg="12">
				<h4 class="mb-4">
					Contents
				</h4>
				<CRow class="mt-4">
					<CCol lg="12">
						<label>Static page content (EN)</label>
						<BaseRichTextEditor
							v-model="content"
							:is-valid="!$v.content.$error"
							:invalid-feedback="$t('global.error.required')"
							name="content"
							size="large"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Static page content (TH)</label>
						<BaseRichTextEditor
							v-model="contentTH"
							:is-valid="!$v.contentTH.$error"
							:invalid-feedback="$t('global.error.required')"
							name="contentTH"
							size="large"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<!-- end Content -->
			<!-- SEO -->
			<CCol lg="12">
				<h4 class="mb-4">
					SEO
				</h4>
				<CRow>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitle"
							label="Meta title (EN)"
							rows="2"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitleTH"
							label="Meta title (TH)"
							rows="2"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeyword"
							label="Meta keywords (EN)"
							rows="3"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeywordTH"
							label="Meta keywords (TH)"
							rows="3"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescription"
							label="Meta description (EN)"
							rows="6"
							class="mb-4"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescriptionTH"
							label="Meta description (TH)"
							rows="6"
							class="mb-4"
						/>
					</CCol>
				</CRow>
				<BaseMediaSelectorBox
					v-model="metaImage"
					title="Meta image"
					no-image-text="No meta image"
				/>
				<hr>
			</CCol>
			<!-- end SEO -->
			<CCol
				lg="12"
				class="d-flex justify-content-end align-items-center"
			>
				<a
					v-if="isEditMode"
					href="#"
					class="btn-remove mr-auto"
					@click.prevent="handleRemove"
				>
					Remove page
				</a>
				<router-link
					:to="{ name: 'Pages' }"
					class="link-button"
				>
					<CButton
						class="mr-3"
						color="tertiary"
					>
						Cancel
					</CButton>
				</router-link>
				<CButton
					:disabled="isLoading"
					type="submit"
					color="primary"
				>
					{{ isEditMode ? 'Save changes' : 'Create' }}
				</CButton>
			</CCol>
		</CRow>
	</form>
</template>

<script>
import { required, requiredIf, helpers, url } from 'vuelidate/lib/validators';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import { PAGE_LEVEL, PAGE_LEVEL_OPTIONS } from '../enums/pages';
import { pathOr, scrollToTop } from '../assets/js/helpers';
import { transformedPageDropdownOption } from '../assets/js/transform/pages';
import slug from '../regex/slug';
import {
	STATUS as mappingStatus,
	VISIBILITY as mappingVisibility,
} from '../mapping/pages';

const slugify = require('slugify');

export default {
	name: 'FormPages',
	components: {
		BaseMediaSelectorBox,
	},
	validations() {
		return {
			pageParentLevelOne: {
				required: (value) => {
					if (this.isParentSelectable) {
						return required(value);
					}
					return true;
				},
			},
			name: {
				required,
			},
			nameTH: {
				required,
			},
			content: {
				required: requiredIf((vm) => !vm.isLevelOne),
			},
			contentTH: {
				required: requiredIf((vm) => !vm.isLevelOne),
			},
			slug: {
				required,
				format: helpers.regex('slug', slug),
				duplicate: () => !this.isDuplicateSlug,
			},
			customUrl: {
				url,
			},
		};
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		pageList: {
			type: Array,
			default: () => [],
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isDuplicateSlug: {
			type: Boolean,
			default: false,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			level: PAGE_LEVEL.ONE,
			column: null,
			PAGE_LEVEL,
			PAGE_LEVEL_OPTIONS,
			pageParentLevelOne: null,
			name: '',
			nameTH: '',
			customUrl: null,
			slug: '',
			status: true,
			visibility: false,
			headerVisibility: false,
			sidebarVisibility: false,
			content: '',
			contentTH: '',
			metaTitle: '',
			metaTitleTH: '',
			metaKeyword: '',
			metaKeywordTH: '',
			metaDescription: '',
			metaDescriptionTH: '',
			metaImage: null,
		};
	},
	computed: {
		// Pages dropdown options
		pageParentLevelOneOptions() {
			return this.pageList.map(transformedPageDropdownOption);
		},
		isParentSelectable() {
			return this.level !== PAGE_LEVEL.ONE;
		},
		pageParentId() {
			if (this.isParentSelectable) {
				return pathOr(null, ['pageParentLevelOne', 'value'])(this);
			}
			return null;
		},
		isValidSlug() {
			const isError = pathOr(false, ['$v', 'slug', '$error'])(this);
			return isError ? false : null;
		},
		validateSlugMessage() {
			const validateRequired = pathOr(false, ['$v', 'slug', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'slug', 'format'])(this);
			const validateDuplicate = pathOr(false, ['$v', 'slug', 'duplicate'])(this);

			if (!validateRequired) {
				return this.$t('global.error.required');
			} else if (!validateFormat) {
				return this.$t('global.slug.slugFormat');
			} else if (!validateDuplicate) {
				return this.$t('global.slug.duplicateSlug');
			}
			return null;
		},
		slugHelperText() {
			return this.isValidSlug !== false ? this.$t('global.slug.slugFormat') : '';
		},
		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
		isLevelOne() {
			return this.level === PAGE_LEVEL.ONE;
		},
	},
	mounted() {
		if (this.isEditMode) {
			this.level = this.data.level || PAGE_LEVEL.ONE;
			this.name = this.data.name;
			this.nameTH = this.data.nameTH;
			this.customUrl = this.data.customUrl;
			this.slug = this.data.slug;
			this.status = this.data.status;
			this.visibility = this.data.visibility ?? true;
			this.headerVisibility = this.data.headerVisibility ?? false;
			this.sidebarVisibility = this.data.sidebarVisibility ?? false;
			this.pageParentLevelOne = this.pageParentLevelOneOptions.find((option) => option.value === this.data.parentLevelOneId);
			this.content = this.data.content;
			this.contentTH = this.data.contentTH;
			this.metaTitle = this.data.metaTitle;
			this.metaTitleTH = this.data.metaTitleTH;
			this.metaKeyword = this.data.metaKeyword;
			this.metaKeywordTH = this.data.metaKeywordTH;
			this.metaDescription = this.data.metaDescription;
			this.metaDescriptionTH = this.data.metaDescriptionTH;
			this.metaImage = this.data.metaImage;
		}
	},
	methods: {
		handleSelectedLevel(level) {
			// Should clear parent id value
			if (level === PAGE_LEVEL.ONE) {
				this.pageParentLevelOne = null;
			}
		},
		handleUpdateStatus(value) {
			this.status = value;

			// When set status to false, should set visibility to false too
			if (value === false) {
				this.handleUpdateVisibility(false);
			}
		},
		handleUpdateVisibility(value) {
			this.visibility = value;
		},
		handleUpdateHeaderVisibility(value) {
			this.headerVisibility = value;
		},
		handleUpdateSidebarVisibility(value) {
			this.sidebarVisibility = value;
		},
		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				parent_id: this.isLevelOne ? null : this.pageParentLevelOne.value,
				name_en: this.name,
				name_th: this.nameTH,
				custom_link: this.customUrl ? this.customUrl : null, // if is empty string change it to null
				slug: this.slug,
				is_active: this.status,
				is_visible: this.visibility,
				is_header_visible: this.headerVisibility,
				is_sidebar_visible: this.sidebarVisibility,
				content_en: this.content || undefined,
				content_th: this.contentTH || undefined,
				seo: {
					title_en: this.metaTitle,
					title_th: this.metaTitleTH,
					description_en: this.metaDescription,
					description_th: this.metaDescriptionTH,
					keyword_en: this.metaKeyword,
					keyword_th: this.metaKeywordTH,
					image: this.metaImage
						? this.metaImage.id
						: null,
				},
			};

			this.$emit('onSubmit', params);
		},
		handleAutoFillMetaTitleAndSlug() {
			if (!this.metaTitle) {
				this.metaTitle = this.name;
			}

			if (!this.slug) {
				this.slug = slugify(this.name, {
					lower: true,
					strict: true,
				});
				this.$v.slug.$touch();
			}
		},
		handleAutoFillMetaTitleTH() {
			if (!this.metaTitleTH) {
				this.metaTitleTH = this.nameTH;
			}
		},
		handleClearErrorSlug() {
			this.$emit('onClearErrorSlug');
		},
		handleRemove() {
			this.$emit('onRemove');
		},
		getVisibilityText(value) {
			const text = mappingVisibility[value] || '';
			return this.$t(text);
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.level-label {
		margin-bottom: rem(4);
	}

	.footer-column-label {
		margin: rem(16) 0 rem(4) 0;
	}

	.toggle-label {
		width: rem(162);
		margin: 0 rem(24) 0 0;
	}

	.toggle-status,
	.toggle-visibility {
		margin-right: rem(12);
	}

	.row-parent-page {
		// margin left, right come from .row
		margin-top: rem(16);
		margin-bottom: rem(-40);
	}

	.toggle-group {
		& + .toggle-group {
			margin-top: rem(32);
		}
	}
</style>
